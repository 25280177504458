.message_talk {
    /* -ms-user-select: none !important;
    -moz-user-select: -moz-none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    user-select:none !important; */

    -webkit-font-smoothing: subpixel-antialiased;

	-webkit-touch-callout: none;

	-webkit-user-select: none;

	-khtml-user-select: none;

	-ms-user-select: none;

	-moz-user-select: none;

	user-select: none;

}
.input_no {
    letter-spacing: 0px !important;
    word-spacing: -5px !important;
    /* -webkit-touch-callout: none; */
}
.no {
    /* -webkit-touch-callout: none; */
}




