.folderName {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  max-width: 48px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 1.2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}