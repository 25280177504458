.latex-container {
  display: flex;
  flex-direction: column;
  //gap: 0.5rem;
  overflow-wrap: break-word;
  overflow-x: auto;
  max-width: unset;

  .katex {
    font-size: 0.875rem;
    .base,
    .strut {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }

    .katex-mathml {
      display: none;
    }

    .mathnormal{
      font-size: 0.875rem
    }
    //.mord {
    //  .vlist {
    //    span {
    //      &:nth-child(1) {
    //        top: -2.3em !important;
    //      }
    //
    //      &:nth-child(2){
    //        top: -3em !important;
    //      }
    //
    //      &:nth-child(3) {
    //        top: -3.3em !important;
    //      }
    //    }
    //
    //  }
    //}
  }

  .katex-display {
    > .katex {
      text-align: left !important;
    }
  }

  p {
    margin-bottom: 0.5rem;

    &:nth-child(1) {
      margin: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin: 0.5rem 0
  }

  h2 {
    font-size: 1rem;
  }

  ol {
    list-style-type: decimal;

    > li {
      padding-left: 0.375em;
      &:not(:has(> span.katex)) {
        align-content: center ;
        padding: 0.71em 0;
      }
    }
  }

  table {
    border-collapse: collapse;
    border: 0;
    border-style: hidden;
  }
  thead th,
  tbody th {
    background-color: #3b82f6;
  }
  th,
  td {
    border: 1px solid #e2e2e2;
    background-clip: padding-box;
    scroll-snap-align: start;
    text-align: center;
    padding: 0.6rem;
    min-width: 6rem;
    margin: 0;
  }
  thead {
    position: relative;
  }
  thead th {
    position: sticky;
    top: 0;
    border-top: 0;
    background-clip: padding-box;
  }
  thead th.pin {
    left: 0;
    border-left: 0;
  }
  tbody {
    position: relative;
  }
  tbody tr:last-child th,
  tbody tr:last-child td {
    border-bottom: 0;
  }
  tbody tr {
    background-color: #fff;
  }
  tbody th {
    background-clip: padding-box;
    border-left: 0;
    position: sticky;
    left: 0;
  }
  tbody tr td {
    color: #000;
  }
}
