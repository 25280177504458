/* .list + label {
	display: inline-block;
	width: 28px;
    height: 28px;
	border: 1px solid white;
	cursor: pointer;
}
.list:checked + label {
	background-color: #428bca;
}

.list {
	display: none;
} */

.checkbox {
	display: none;
}
.checkbox + label{
	display: inline-block;
	width: 22px;
    height: 22px;
	border-radius: 20px;
	cursor: pointer;
	/* border: solid 1px #626262; */
  	/* background-color: #fff; */
	/* background-color: rgb(220,220,220) */
	background: rgb(220,220,220) url('../../asset/Img/check.png') no-repeat 0 0px / contain;;
}

.checkbox.t:checked + label{
	background:  #487FBA url('../../asset/Img/check.png') no-repeat 0 0px / contain;;
	/* background-color:; */
}
.checkbox.s:checked + label{
	background:  #F5B21A url('../../asset/Img/check.png') no-repeat 0 0px / contain;;
	/* background-color:; */
}

input.check + label {
	display: inline-block;
	width: 20px;
    height: 20px;
	border: 1px solid skyblue;
    border-radius: 20px;
	cursor: pointer;
}

input.check:checked + label {
	background-color: #428bca;
}