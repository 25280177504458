.titleWrapper {
  display: flex;
  margin-bottom: 5px;

  .classNameText {
    font-size: 14px;
    font-weight: 700;

    span.classRoomNameText {
      color: #B0B0B0;
      font-size: 12px;
      font-weight: 400;
      margin-left: 5px;
    }
  }

}
