.ReactCrop__crop-selection {
    background-image: none !important;
    border-image: none !important;
    border : none !important
}
.ReactCrop__drag-handle {
    width : 40px;
    height : 40px;
}
.ReactCrop__drag-handle.ord-nw {
    border-left : 5px solid orange  !important;
    border-top : 5px solid orange !important;
}
.ReactCrop__drag-handle.ord-ne {
    border-right : 5px solid orange  !important;
    border-top : 5px solid orange !important;
}
.ReactCrop__drag-handle.ord-se {
    border-right : 5px solid orange  !important;
    border-bottom : 5px solid orange !important;
}
.ReactCrop__drag-handle.ord-sw {
    border-left : 5px solid orange  !important;
    border-bottom : 5px solid orange !important;
}
.ReactCrop__drag-handle::after {
    display: none !important;
    background-color: white !important;
}