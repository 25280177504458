.MyInfoMainContainer {
  width: 100%;
  margin: 0 16px;

  .purchaseButton {
    width: 100%;
    background-color: transparent;
    height : 72px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  
    padding: 8px 10px;
  
    span {
      width: 100%;
      text-align: left;
      color: #333;
      font-size: 18px;
      font-weight: 700;
    }
  }
}