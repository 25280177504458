.selectTicket {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 12px;
  padding-top: 12px;
  padding-bottom: 56px;
  gap: 24px;

  .closeButton {
    position: absolute;
    right: -8px;
    top: -8px;
    font-size: 18px;
    font-weight: 400;
    width: 24px;
    height: 24px;
    border: none;
    background-color: white;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0;
    background: none;

    .titleContainer {
      display: flex;
      flex-direction: column; 
      align-items: flex-start;
      gap: 8px;

      .title {
        font-size: 18px;
        font-weight: 700;
      }

      .desc {
        font-size: 14px;
        font-weight: 400;
      }

      .price {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .amountContainer {
      display: flex;
      flex-direction: row; 
      align-items: flex-end;

      .cell {
        min-width: 32px;
        height: 32px;
        font-size: 24px;
        font-weight: 700;
        background-color: white;
        text-align: center;
        vertical-align: middle;
        border: 0;
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row; 
      align-items: flex-end;
      gap: 4px; 
    }
  }

  .totalAmount {
    text-align: right; 
    font-size: 24px;
    font-weight: 700;
  }

  .selectButton {
    width: 64px;
    height: 36px;
    background-color: black;
    font-size: 18px;
    font-weight: 700;
    color: white;
    border-style: none;
    border-radius: 8px;
  }

  .purchaseButton {
    width: 100%;
    height: 48px;
    background-color: black;
    font-size: 18px;
    font-weight: 700;
    color: white;
    border-style: none;
    border-radius: 8px;
  }
}