.MenuContainer {
  width: 100%;
  background-color: transparent;
  height : 72px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  padding: 8px 10px;

  & + & {
    border-top: 1px solid #f0f0f0;
  }

  .ContentSection {
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 700;
  }

}
