.MyQuestionContainer {
  width: 100%;
  margin: 0;
  box-sizing: border-box;

  .FilterSection {
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    width: 100%;

    box-sizing: border-box;
  }

  .QuestionListSection {
    max-width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    height: calc(100% - 48px);
  }
}
