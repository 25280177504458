.LectureVideoItemContainer {
  box-sizing: border-box;
  display: flex;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: auto;
  min-height: 264px;
  border-radius: 8px;

  .ThumbnailSection {
    max-width: 100%;
    height: auto;
    overflow: hidden;
  }

  h2 {
    text-align: center;
    padding: 0 10px 4px;
    margin: 10px 0 4px;
    font-size: 12px;
    width: 100%;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ViewSection {
    font-size: 10px;
    font-weight: 800;
    color: rgb(247, 184, 101);
  }

  .NameSection {
    font-size: 10px;
  }
}
