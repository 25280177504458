@font-face {
  font-family: "Nanum";
  src: url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");
  /* src: url("./asset/fonts/NanumSquareL.otf");
    src: url("./asset/fonts/NanumSquareR.otf");
    src: url("./asset/fonts/NanumSquareB.otf"); */
}

@import url("./stylesheet/palette.scss");
@import url("./stylesheet/font.scss");

.mother_ {
  -webkit-font-smoothing: subpixel-antialiased;

  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -ms-user-select: none;

  -moz-user-select: none;

  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

/* a:active{
  background-color: transparent
}
a:visited{
  background-color: transparent;
} */
input,
select,
textarea,
button,
a,
label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  font-family: "Spoqa Han Sans Neo", "sans-serif", "Apple SD Gothic Neo";
}

ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
}

.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
a {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small_text {
  font-size: 12px;
}

.medium_text {
  /* font-family: nanumL; */
  font-weight: bold;
  font-size: 16px;
  padding: 10px 0px;
}

.UploadDashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 80%;
  min-width: 88px;
  border-radius: 0 0 8px 8px;
  right: 0;
  z-index: 2;
}

.UploadDashboard button {
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 20px;
  color: #fff;
  border: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  overflow-x: hidden;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  color: #262626;
}

div#root {
  height: 100%;
}

input,
button {
  border: none;
  cursor: pointer;
  background: none;
}

input {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}


/*.katex .katex-mathml {*/
/*  display: none;*/
/*}*/

/*.latex-container {*/
/*  color: #242424 !important;*/
/*  white-space: pre-line;*/
/*  overflow-x: auto;*/
/*}*/

/*.latex-container > p {*/
/*  margin: 10px 0;*/
/*}*/

.custom-textfield .MuiInput-root{
  color: #FFF
}

.custom-textfield .MuiInput-root::before{
  border: 0 !important;
}

.custom-textfield .MuiInput-root::after{
  border: 0 !important;
}

.latex-container  > ol, .latex-container  > ul {
  list-style-position: inside;
}



.semohaeSendTalk {
  max-width: 85vw !important;
}
.semohaeReceiveTalk{
  max-width: calc(90vw - 93px) !important;
}

@media (min-width: 870px) {
  .semohaeSendTalk {
    max-width: 70vw !important;
  }
  .semohaeReceiveTalk{
    max-width: 70vw !important;
  }
}



.message_talk_container   img{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}