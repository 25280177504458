.inherit {
  all: inherit;
}

.full {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  border: unset;
  border-radius: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 150ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButton {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  top: 50%;
  left: 50%;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 96px;
  height: 96px;
  border-radius: 50%;
  font-size: 58px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(4px);
  justify-content: center;
  & > svg {
    color: white;
    width: 58px;
    height: 58px;
  }
}

.controllerWrapper {
  // opacity: 0;
  position: absolute;
  display: flex;
  z-index: 1;
  bottom: 0;
  width: inherit;
}
