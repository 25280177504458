.MainContainer {
  position: absolute;
  width: 100%;
  height: 100vh;

  .CardSection {
    width: 80%;
    min-height: 100px;
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFF;
    border-radius: 8px;
    padding: 16px 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
    text-align: center;
  }

  .BtnWrapper {
    display: flex;
    justify-content: space-around;

    button {
      paddding: 8px;
      outline: none;
      background: none;
      border: none;
      .linkImg{
        width: 24px;
        height: 24px;
      }
    }
  }
}