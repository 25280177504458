.box{
    animation: linearGradientMove .3s infinite linear;
    /* outline: 1px solid #333;
    outline-offset: -1px; */
    background: 
    linear-gradient(90deg, #F5B21A 50%, transparent 0) repeat-x,
    linear-gradient(90deg, #F5B21A 50%, transparent 0) repeat-x,
    linear-gradient(0deg, #F5B21A 50%, transparent 0) repeat-y,
    linear-gradient(0deg, #F5B21A 50%, transparent 0) repeat-y;
    background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
}
@keyframes linearGradientMove {
    100% {
        background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
    }
}

.mosaic{
    /* -webkit-mask:url('../../asset/Img/mosaic.png') repeat 0 0; */
    -webkit-mask: url('../../asset/Img/mosaic.png') repeat 0 0;
    /* background-color: red; */
    /* background: linear-gradient(to right, red, blue);
    background-position-x: 0;
    animation: size 3s infinite linear; */
    /* mask-image: image(url(mask.png), skyblue); */
    -webkit-mask-size: 250px;
}
/* body {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

*, *::before, *::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0;
	width: 400px;
	height: 300px;
	border-radius: 10px;
	overflow: hidden;
	padding: 2rem;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
		animation: opacityChange 3s infinite alternate;
	}
}

@keyframes opacityChange {
	50% {
		opacity:1;
	}
	100% {
		opacity: .5;
	}
} */