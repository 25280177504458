.progressPadding {
  padding: 8px 0 4px;
  width: 100%;
}
.progressBar {
  position: relative;
  margin: 0 12px;
  display: flex;
}

.progressFill {
  cursor: pointer;
  height: 8px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  accent-color: rgba(255, 255, 255, 0.6);
}

.progressThumbnail {
  cursor: pointer;
  position: absolute;
  top: -58px;
  left: 0;
  background-color: #fff;
  pointer-events: none;
  transform: translateX(-50%);
}
