.wrapper {
  display: flex;
  align-items: center;
}

.button {
  all: unset;
  width: auto;
  height: 24px;
  & > svg {
    color: white;
  }
}

.valumeProgress {
  margin-left: 14px;
  height: 4px;
  width: 100px;
  background: linear-gradient(
    to right,
    white 0%,
    white 0%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  border-radius: 8px;
  outline: none;
  transition: all 100ms ease-in;
  -webkit-appearance: none;
  accent-color: white;
  &::-webkit-slider-thumb {
    transition: all;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &.hide {
    margin-left: 0px;
    width: 0px;
    &::-webkit-slider-thumb {
      height: 0px;
      width: 0px;
    }
  }
}
