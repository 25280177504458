.QuestionRegisterButtonWrapper {
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  button {
    border: none;
    background: #487fba;
    width: 100%;
    height: 48px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .pdfButtonSolution {
    background: #ff9900;
  }
  .pdfButtonNotSolution {
    background: #1172be;
  }
}
