:root {
  /* Primary Colors */
  --primary: #fe544e;
  --primary-color-primary-10: #fef3f1;
  --primary-color-primary-20: #febcb1;
  --primary-color-primary-30: #feb5a7;
  --primary-color-primary-40: #fea69b;
  --primary-color-primary-50: #fe8f86;
  --primary-color-primary-60: #fe746e;
  --primary-color-primary-70: #fe544e;
  --primary-color-primary-80: #cd403d;

  /* Grey Colors */
  --neutral-color-natural-10: #fafafa;
  --neutral-color-natural-20: #f0f0f0;
  --neutral-color-natural-30: #d9d9d9;
  --neutral-color-natural-40: #bfbfbf;
  --neutral-color-natural-50: #8c8c8c;
  --neutral-color-natural-60: #434343;
  --neutral-color-natural-70: #262626;
  --neutral-color-natural-80: #141414;

  /* Error Colors */
  --danger: #cf1322;
  --danger-color-danger-10: #fff1f0;
  --danger-color-danger-20: #ffccc7;
  --danger-color-danger-30: #ffa39e;
  --danger-color-danger-40: #ff7875;
  --danger-color-danger-50: #ff4d4f;
  --danger-color-danger-60: #f5222d;
  --danger-color-danger-70: #cf1322;
  --danger-color-danger-80: #a8071a;

  /* Warning Colors */
  --warning: #ffad0d;
  --warning-color-warning-10: #fffdfa;
  --warning-color-warning-20: #fff9ee;
  --warning-color-warning-30: #fff7e1;
  --warning-color-warning-40: #ffeab3;
  --warning-color-warning-50: #ffdd82;
  --warning-color-warning-60: #ffc62b;
  --warning-color-warning-70: #ffad0d;
  --warning-color-warning-80: #fe9b0e;

  /* Success Colors */
  --success: #47b881;
  --success-color-success-10: #fbfefc;
  --success-color-success-20: #f2faf6;
  --success-color-success-30: #e5f5ec;
  --success-color-success-40: #c0e5d1;
  --success-color-success-50: #97d4b4;
  --success-color-success-60: #6bc497;
  --success-color-success-70: #47b881;
  --success-color-success-80: #0c9d61;

  /* Info Colors */
  --info: #3b82f6;
  --info-color-info-10: #f8fcff;
  --info-color-info-20: #f1f8ff;
  --info-color-info-30: #e4f2ff;
  --info-color-info-40: #bdddff;
  --info-color-info-50: #93c8ff;
  --info-color-info-60: #4ba1ff;
  --info-color-info-70: #3b82f6;
  --info-color-info-80: #3a70e2;
}
