.ToSAgreementModalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
  .ToSAgreementModalContent {
    position: relative;
    padding: 16px 10px;
    background-color: white;
    width: 80%;
    height: 60vh;
    overflow: auto;
  }
}
