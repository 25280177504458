.container {
  display: flex;
  position: relative;
  width: auto;
  justify-content: center;
}

.selectWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: auto;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 12px 20px;
  gap: 16px;
  position: absolute;
  bottom: 20px;
  border-radius: 10px;
  > button {
    all: unset;
    color: #eee;
  }
}

.speedText {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: none;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  user-select: none;
  width: fit-content;
}
