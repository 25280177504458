.TutorialContainer {
  color: #333;
  width: 100vw;
  height: 100vh;
  padding: 10px 16px;
  box-sizing: border-box;

  button {
    background: none;
    border: none;
  }

  .titleWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  div > img {
    height: 80vh;
    width: auto;
    object-fit: contain;
  }
}
