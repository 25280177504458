.buttonSection {
  display: flex;
  gap: 10px;
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: none;
    font-size: 16px;
  }
}