.wrapper {
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
}
