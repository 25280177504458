.LectureVideoContainer {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 100%;
  // min-height: 400px;
  height: auto;
  background-color: #f4a2350f;

  h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: left;
  }

  .MoreLectures {
    color: rgb(247, 184, 101);
    text-align: right;
  }

  .LectureVideoSection {
    width: 100%;
    :global {
      .slick-track {
      }
    }
  }

  .ItemSection {
    width: 100%;
    max-height: 100%;
    margin: 0;
  }

  .NoContent {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    a {
      border-radius: 12px;
      padding: 4px 8px;
      min-width: 120px;
      background-color: transparent;
      height: 32px;
      border: 2px solid rgb(247, 184, 101);
      font-weight: 600;
      color: rgb(247, 184, 101);
    }
  }

  .UploadVideoButton {
    margin: 0 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      border-radius: 12px;
      padding: 4px 8px;
      min-width: 120px;
      background-color: transparent;
      height: 32px;
      border: 2px solid rgb(247, 184, 101);
      font-weight: 600;
      color: rgb(247, 184, 101);
    }
  }
}
