.InviteModalContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .InviteModalContent {
    padding: 16px 10px;
    background-color: white;
    width: 80%;
    height: 60vh;
    overflow: hidden;

    .ButtonSection {
      display: flex;
      padding: 10px 0;
      justify-content: space-around;
      align-items: center;
      margin: 24px 0;
      background-color: #d0d0d0;
      border-radius: 8px;

      button {
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
