.subContainer{
  padding: 16px 20px 10px 20px;
  overflow: hidden;
  &.folder{
    //height: 35%;
  }
  &.book{
    height: 75%;
    border-top: 1px solid #e6e6e6;
    margin-top: 10px;
  }
  .titleSection{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 25px;
  }
  .mainContentWrapper{
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 25px);
    overflow: scroll;
    justify-content: center;
  }
  .folderWrapper{
    display: flex;
    overflow: scroll;
  }
}
