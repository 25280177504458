.bookLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 4px;
  padding: 0 10px;
  border-radius: 20px;
  background: #E7AAAA;
  font-size: 11px;
  color: #FFF;
}

.bookTypeLabel {
  position: absolute;
  bottom: 10px;
  font-size: 16px;
  left: 5px;
  color: white;
  text-shadow: -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000;
}