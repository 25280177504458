.MuiSlider-rail {
  /* backgroundColor: '#fff'; */
  background-color: #aaaaaa !important;
}
.MuiSlider-root {
  color: #ff7d0d !important;
}
/* input{
    appearance: 'none';
    -webkit-appearance: none;
    -moz-appearance: none;
} */
input {
  /* font-family: "Nanum"; */
}
input[type="number"].numberForm::-webkit-inner-spin-button,
input[type="number"].numberForm::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input::placeholder {
  /* color: #a8b7c7; */
  /* font-weight: bold; */
  /* padding-left: 0px;
  font-size: 12px; */
}
select {
  color: #777;
  height: 32px;
  font-weight: bold;
  font-size: 14;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

/* select::selection{
    color: gold;
} */
select::-ms-expand {
  display: none;
}
/* input.check {
	display: none;
} */

/* input.check + label {
	display: inline-block;
	width: 20px;
    height: 20px;
	border: 1px solid skyblue;
    border-radius: 20px;
	cursor: pointer;
} */

/* input.check:checked + label {
	background-color: #428bca;
} */

/* a {
  text-decoration: none;
  color: #428bca;
  font-size: 13;
  font-weight: "bold";
} */
/* input:focus{
    outline : 1px solid #F5B21A;
} */

.input_s:focus {
  outline: 1px solid #f5b21a;
}
.input_t:focus {
  outline: 1px solid #487fba;
  border-radius: 5;
}
.input_no {
  outline: none;
}
.input_no:focus {
  outline: none !important;
}
