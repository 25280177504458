.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 4px 16px;
  margin-bottom: 8px;
  box-sizing: border-box;
  gap: 12px;
  & > svg {
    color: white;
  }
}

.timeText {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: none;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  user-select: none;
  flex: 1;
  width: 100%;
}
