.orderContainer {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 48px;
  padding-bottom: 56px;
  padding-left: 12px;
  padding-right: 12px;
  gap: 12px;
  background-color: #F1F1F1;

  .closeButton {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 18px;
    font-weight: 400;
    width: 24px;
    height: 24px;
    border: none;
  }

  .section {
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .price {
    gap: 16px;

    .item {
      display: flex;
      flex-direction: row; 
      justify-content: space-between;
      gap: 8px;

      .titleContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        .title {
          font-size: 18px;
          font-weight: 700;
        }

        .desc {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .price {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .total {
      text-align: right; 
      font-size: 24px;
      font-weight: 700;
    }
  }

  .method {
    gap: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        height: 72px;
        background-color: #F1F1F1;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.4);

        .title {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }

        .title:active {
          color: rgba(0, 0, 0, 0.8);
        }

        .image {
          flex: 1;
          max-height: 32px;
        }
      }

      .selected {
        border: 1px solid rgb(0, 0, 0);
        background-color: white;

        .title {
          color: rgb(0, 0, 0);
        }
      }
    }
  }

  .guide {
    padding: 0;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.4);

    .title {
      margin: auto;
      font-size: 14px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .virtualAccount {
    gap: 0px;
  }

  .check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .checkLabel {
      font-size: 14px;
    }

    .checkLink {
      margin-left: 12px;
      font-size: 12px;
      text-decoration: underline;
    }
  }

  .submitButton {
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 700;
    color: white;
    border-style: none;
    border-radius: 8px;
  }

  .submitButton:disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }
}